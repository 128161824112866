import { z } from 'zod';
import {
  factoryEmailFormatErrorMessage,
  factoryMaxLengthErrorMessage,
  factoryMinLengthErrorMessage,
  factoryRequiredErrorMessage,
} from '~/utils/messages';

export const schema = z.object({
  email: z.preprocess(
    (value) => (value === '' ? undefined : value),
    z
      .string({ required_error: factoryRequiredErrorMessage('メールアドレス') })
      .email(factoryEmailFormatErrorMessage('メールアドレス')),
  ),
  password: z.preprocess(
    (value) => (value === '' ? undefined : value),
    z
      .string({ required_error: factoryRequiredErrorMessage('パスワード') })
      .min(8, factoryMinLengthErrorMessage('パスワード', 8))
      .max(30, factoryMaxLengthErrorMessage('パスワード', 30)),
  ),
});
