import { useForm } from '@conform-to/react';
import { getZodConstraint } from '@conform-to/zod';
import { useLoaderData, useFetcher } from '@remix-run/react';
import { PtnButton } from '~/components/ui/Buttons/PtnButton';
import { PtnErrorMessages } from '~/components/ui/Forms/ErrorMessages';
import {
  PtnField,
  PtnFieldset,
  PtnLabel,
} from '~/components/ui/Forms/PtnFieldset';
import { PtnInputText } from '~/components/ui/Forms/PtnInputText';
import { useErrorHandler } from '~/components/ui/Others/PtnToastProvider';
import { PtnLink } from '~/components/ui/PtnLink';
import { loginAction } from '~/routes/login/action';
import { loginLoader } from '~/routes/login/loader';
import { schema } from '~/schemas/login';
import {
  PARTNER_HELP_SITES_URLS,
  PARTNER_ETHNAM_PASSWORD_RESET_URL,
  PRIVACY_POLICY_URL,
} from '~/utils/urls';
import type { MetaFunction } from '@remix-run/node';

export const meta: MetaFunction = () => {
  return [{ title: 'ログイン' }];
};

export const action = loginAction;

export const loader = loginLoader;

const Login = (): React.ReactElement => {
  const { errorMessages } = useLoaderData<typeof loader>();

  useErrorHandler(errorMessages);

  const fetcher = useFetcher();
  const [form, fields] = useForm({
    lastResult: fetcher.data,
    constraint: getZodConstraint(schema),
    shouldValidate: 'onBlur',
  });

  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 top-0 bg-orange-100 z-0"></div>
      <div className="relative z-1 flex flex-col justify-center items-center py-5">
        <img
          src="/service_logo.png"
          alt="サービスロゴ"
          className="w-full max-w-[300px] h-auto"
        />

        <fetcher.Form
          method="post"
          id={form.id}
          aria-invalid={form.errors ? true : undefined}
          className="w-[calc(100%_-_16px)] sm:w-auto mt-5 flex flex-col items-center bg-gray-100 rounded-40 py-[40px] px-2 sm:px-[80px]"
        >
          <p className="text-28 font-bold flex justify-center">
            管理画面にログイン
          </p>
          <PtnFieldset className="mt-4 w-full sm:w-[358px]">
            <PtnField className="flex flex-col">
              <PtnLabel className="mb-0.5" htmlFor={fields.email.id}>
                メールアドレス
              </PtnLabel>
              <PtnErrorMessages
                name={fields.email.name}
                className="flex flex-col"
                errors={fields.email.errors}
              >
                <PtnInputText
                  id={fields.email.id}
                  name={fields.email.name}
                  invalid={!!fields.email.errors || undefined}
                  defaultValue={fields.email.initialValue as string}
                />
              </PtnErrorMessages>
            </PtnField>

            <PtnField className="mt-2 flex flex-col">
              <PtnLabel className="mb-0.5" htmlFor={fields.password.id}>
                パスワード
              </PtnLabel>
              <PtnErrorMessages
                name={fields.password.name}
                className="flex flex-col"
                errors={fields.password.errors}
              >
                <PtnInputText
                  id={fields.password.id}
                  type="password"
                  name={fields.password.name}
                  invalid={!!fields.password.errors || undefined}
                  defaultValue={fields.password.initialValue as string}
                />
              </PtnErrorMessages>
            </PtnField>
          </PtnFieldset>
          <PtnButton className="mt-4 w-full" variant="primary" type="submit">
            ログイン
          </PtnButton>
          <PtnLink
            className="mt-4 inline-block text-16 font-bold"
            to={PARTNER_ETHNAM_PASSWORD_RESET_URL}
          >
            パスワードをお忘れの方
          </PtnLink>
        </fetcher.Form>

        <div className="flex mt-5 text-16 space-x-3 justify-center">
          <PtnLink to={PRIVACY_POLICY_URL}>プライバシーと利用規約</PtnLink>
          <PtnLink to={PARTNER_HELP_SITES_URLS.CONTACT}>お問い合わせ</PtnLink>
        </div>
      </div>
    </>
  );
};

export default Login;
